/* CSS for atlast */
@import "../../../../../../lib/uio1/profile/css/util.less";

/*------------------------------------*\
# fonts
\*------------------------------------*/
// Font-license: https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL
// https://www.fontsquirrel.com/fonts/concert-one
@font-face {
  font-family: "Concert One";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/concert-one.woff2") format("woff2");
}

@font-primary: "Roboto", sans-serif;
@font-secondary: "Roboto", sans-serif;
@font-symbol: "Concert One", cursive;

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.info-link-wrapper {
  .font-size(16);
}

html,
body {
  font-family: @font-secondary, Arial, sans-serif;
  line-height: 26px;
  color: @color-dark;
  font-weight: 400;
}
body {
  .font-size(17);
}

a {
  font-family: @font-secondary;
  font-weight: 500;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  font-family: @font-primary;
  .font-size(20);
}

.introduction-div,
.vrtx-introduction-big,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-organizational-unit-introduction,
.vrtx-introduction {
  font-family: @font-primary;
  .font-size(20);
  line-height: 32px;
}

h1, h2, h3, h4,
.vrtx-toc-header {
  font-family: @font-primary;
  color: @color-dark;
  font-weight: 700;
}

h1, h2, h3, h4,
.vrtx-frontpage-box.vrtx-more-false > h2,
#comments-header-left,
#main *:not(.vrtx-messages) > .vrtx-resource {
  a,
  a.vrtx-title {
    font-family: @font-primary;
    font-weight: 500;
  }
}

h1 {
  .font-size(40);
}

h2 {
  .font-size(26);
}

.vrtx-frontpage-box .item-title,
h3 {
  .font-size(20);
}

h4 {
  .font-size(16);
}

hr {
  border-bottom: 4px solid @color-primary;
  padding-bottom: 10px;
}

#main .vrtx-back a::before {
  background: rgba(0, 0, 0, 0) url("../images/icon-chevron-back.svg") no-repeat
    scroll 0 2px;
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white),
input[type="button"]:not(.red),
input[type="submit"]:not(.submit-comment-button):not(.red),
input[type="cancel"]:not(.red),
.header-search-expand,
button {
  font-family: @font-primary;
  color: @color-light;
  background-color: @color-contrast;
  .transition(all, 0.3s, ease-in);
  &:focus,
  &:hover {
    background-color: @color-dark;
    color: @color-light;
  }
  a:focus,
  a:hover {
    text-decoration: underline;
  }
}
#error.not-for-ansatte #head-wrapper #head {
  height: 290px;
}
.not-for-ansatte {
  .header-search-collapsable .header-search-expand {
    background-color: @color-dark;
    color: @color-light;
  }
  #head-wrapper {
    background: url(../images/atlast-banner-bg-2024.png) left bottom repeat-x;
    .header-search {
      top: 18px;
      z-index: 10;
      input[type="text"] {
        background: @color-primary--thinair;
        .placeholderColor(@color-neutral--dark, @color-neutral--dark);
        color: @color-dark;
      }
      .header-search-expand {
        background: url(../uio1/profile/images/responsive/search-icon-active.svg)
          9px 9px no-repeat;
        background-size: 20px auto;
        &:focus,
        &:hover {
          background: url(../uio1/profile/images/responsive/search-icon-active.svg)
            9px 9px no-repeat;
          background-size: 20px auto;
        }
      }
      button,
      .header-search-expand {
        background-color: @color-contrast;
        color: @color-light;
        &:focus,
        &:hover {
          background-color: @color-contrast--dark;
          color: @color-light;
        }
      }
    }

    #head {
      height: 250px;
      #header {
        height: 100%;
        width: 550px;
        img#logoimg {
          position: absolute;
          max-height: 240px;
          top: 39px;
          left: -105px;
        }
        a {
          position: absolute;
          display: block;
          color: @color-dark;
          cursor: pointer;
          &#logotext {
            width: 600px;
            display: block;
            .font-size(70);
            top: 30px;
            left: 0px;
            position: absolute;
            height: 80px;
            padding: 20px 0;
          }
          &:hover,
          &:focus {
            color: @color-contrast--dark;
            text-decoration: none;
          }
        }

        span.tagline {
          top: 70px;
          position: absolute;
          left: 0;
          .font-size(20);
        }
      }
    }
    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }
    .uio-app-name {
      display: none;
    }
    .head-menu {
      right: 120px;
      .link-in-head-menu a {
        color: @color-light;
      }
      .vrtx-login-manage-component .vrtx-dropdown-link {
        background: transparent
          url("../images/language-dropdown-toggle-black.png") no-repeat scroll
          99% 53%;
      }
      .language {
        display: none !important;
      }
      ul > li a,
      .vrtx-login-manage-component > a {
        color: @color-neutral--dark;
      }
    }
  }
}
div#content-breadcrumb-container {
  width: 970px;
  margin: 0 auto;
  padding: 10px 0;
}
#main {
  margin-top: 20px;
}

#globalnav {
  background: @color-primary none repeat scroll 0 0;
  border-bottom: 4px solid @color-contrast;
  position: relative;
  ul {
    display: flex;
    justify-content: flex-start;
    background-color: @color-primary;
    li.intranet {
      display: none;
    }
    li.vrtx-current-item:hover {
      background-color: @color-neutral;
    }
    li,
    li.vrtx-active-item {
      margin-bottom: -4px;
      border-bottom: 4px solid @color-contrast--dark;
      padding: 0;
      background: @color-primary;
      color: @color-dark;
      a {
        font-family: @font-primary;
        margin: 0 2px;
        padding: 10px 15px 10px 15px;
        display: block;
        height: 100%;
        color: @color-dark;
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: @color-neutral;
          color: @color-light;
        }
      }
    }
    li.vrtx-active-item {
      background-color: @color-contrast--dark;
      a {
        color: @color-light;
        background-color: transparent;
      }
      &:hover,
      &:focus {
        border-bottom: 4px solid @color-contrast--dark;
        color: @color-light;
      }
    }
  }
}

#left-main {
  .vrtx-breadcrumb-menu {
    li > span.vrtx-marked,
    li > a.vrtx-marked,
    li.vrtx-child > a.vrtx-marked,
    li.vrtx-parent > a.vrtx-marked {
      background: @color-light;
      color: @color-link;
    }
    li > span.vrtx-marked,
    li > a.vrtx-marked,
    li.vrtx-child > a.vrtx-marked,
    li.vrtx-child > a.vrtx-after-marked {
      border-top: 2px solid transparent;
      border-bottom: 2px solid @color-contrast;
    }
    li > span,
    li > a {
      background: @color-light;
      font-family: @font-primary;
    }
    li.vrtx-parent {
      a {
        background-color: @color-light;
        color: @color-dark;
      }
    }
    li.vrtx-child {
      a {
        border-top: 2px solid transparent;
        padding-left: 30px;
        color: @color-link;
      }
    }
    li.vrtx-child > a:before,
    li.vrtx-child > span:before {
      content: "\003E";
      font-size: 18px;
      color: @color-contrast;
      font-family: @font-symbol;
      margin-top: -0.2ex;
    }
  }
}

#main .vrtx-back a:before {
  background: url(../images/icon-chevron-back.svg) no-repeat 0 3px transparent;
  background-size: auto 80%;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward.svg") no-repeat 0 4px;
  background-size: 20px;
  height: 20px;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right.svg") no-repeat
      center left;
    background-size: 20px 20px;
    height: 20px;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down.svg");
    background-size: 20px 20px;
  }
}

#main
  .button:not(.study-select):not(.edit-link):not(.program-create-print):not(.program-create-send-dialog):not(.white) {
  background-image: url(../images/arrow-forward-hover.svg);
  background-position: 95% 50%;
  background-size: auto 0.8em;
  padding-right: 80px;
  &:hover,
  &:focus {
    background-image: url(../images/arrow-forward-hover.svg);
  }
}

iframe {
  width: 100%;
  height: 360px;
}

table.vrtx-person-listing {
  td.vrtx-person-listing-name {
    .vrtx-image {
      img {
        border-radius: 50%;
        width: 120px;
        padding: 0;
      }
      padding-right: 14px;
    }
  }
}
#vrtx-person-contact-info-wrapper {
  .vrtx-person-image {
    border-radius: 50%;
    width: 120px;
    padding: 0;
  }
}
.round-image {
  img {
    border-radius: 50%;
    max-width: 300px;
    padding: 0;
  }
}
table {
  th {
    font-weight: 700;
    color: @color-dark;
  }
}

.grid-container ul > li:before,
.uio-main ul > li:before {
  content: "\25C6";
  color: @color-contrast;
}

.vrtx-message-line-publish-date,
.lastModified,
div.time-and-place-container,
.vrtx-featured-item .vrtx-date,
*:not(.vrtx-date-info) > .published-date,
.vrtx-event-component-misc,
.vrtx-comment .comment-date {
  font-family: @font-secondary;
}

ul.social-follow {
  margin-left: -14px !important;
  > li {
    &::before {
      content: "";
    }
  }
}

#vrtx-additional-content
  .vrtx-search-main-links-box
  ul
  li#vrtx-search-main-link-altinnhold
  span {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  padding-left: 14px;
}

.vrtx-tags,
.vrtx-authors,
.vrtx-date-info,
#main .vrtx-paging-feed-wrapper,
#main #vrtx-daily-events .vrtx-daily-events-listing,
#main .vrtx-resources .vrtx-daily-events-listing,
#main #vrtx-daily-events .vrtx-resource,
#main .vrtx-resources .vrtx-resource,
#vrtx-person-contact-info-extras,
#vrtx-event-listing #main .vrtx-resources > h2:first-child {
  border-color: @color-primary;
  border-width: 4px;
}

#main .vrtx-frontpage-box {
  &.top-line {
    border-top: 4px solid @color-primary;
    padding-top: 30px;
    margin-bottom: 30px;
  }
  &.big-image .vrtx-box-content {
    margin-left: 0px !important;
  }
}

table.vertical tbody th[scope="row"] {
  border-width: 2px;
  width: 1%;
  white-space: nowrap;
}
table {
  border-left: none;
  border-right: none;
}
table td,
table th {
  border-color: @color-contrast;
  border-width: 2px;
  border-left: none;
  border-right: none;
}

span.vrtx-tag-cloud-title {
  display: none;
}

blockquote {
  border-color: @color-contrast;
  p {
    font-family: @font-primary;
  }
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;
  width: 100%;
  figure {
    margin: 20px 20px !important;
  }
}

body .vrtx-facts-container {
  background: @color-primary--thinair;
  &.primary {
    background: @color-contrast;
  }
}

#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
  .vrtx-frontpage-box.third-box-right {
  border-left: none;
}
#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
  .vrtx-frontpage-box.third-box-left {
  border-right: none;
}
.vrtx-frontpage-box.big-image {
  img {
    width: 100% !important;
  }
  > h2 {
    margin-left: 0 !important;
    //margin-top: 125px;
  }
  .vrtx-box-content {
    margin-left: 0 !important;
  }
  .vrtx-frontpage-box-picture {
    padding-top: 40px;
  }
}
.vrtx-frontpage-box-picture.big-image.half-box-left {
  img {
    padding-right: 20px;
  }
}

.vrtx-paging-feed-wrapper a {
  font-family: @font-primary;
}

#vrtx-searchview {
  #vrtx-content,
  #right-main {
    width: 100%;
  }
  #vrtx-main-content {
    width: 738px;
  }
  #left-main {
    display: none !important;
  }
  .button.submit-button-link {
    font-family: @font-primary;
  }
}

/* Footer */
#bottomnav {
  display: none !important;
}

#footer-wrapper {
  color: @color-light;
  background: @color-contrast;
  background-size: 100% !important;
  margin-top: 0;
  h2 {
    color: @color-light;
  }

  #footers {
    .font-size(16) !important;
    a {
      color: @color-light;
    }
    .info > p {
      float: left;
      margin-top: 0;
      margin-bottom: 10px;
    }
    .eu-flag > span {
      padding-top: 20px;
      float: left;
      padding-left: 10px;
      width: 75%;
    }
    .eu-flag > img {
      padding-top: 20px;
      float: left;
      width: 300px;
    }
    .social-components {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .linkedin {
        background: transparent url(../uio1/images/social-list/black-svg/linkedin.svg) no-repeat
          scroll left center;
      }
      .twitter-x {
        background: transparent url(../uio1/images/social-list/black-svg/twitter-x.svg) no-repeat
          scroll left center;
      }
      .bluesky {
        background: transparent url(/vrtx/dist/resources/images/social-bluesky.svg) no-repeat
          scroll left center;
      }
      .youtube {
        background: transparent url(../uio1/images/social-list/black-svg/youtube.svg) no-repeat
          scroll left center;
      }

      
      .linkedin,
      .twitter-x,
      .bluesky, 
      .youtube {
        height: 45px;
        width: 45px;
        color: transparent;
        background-size: 100% auto;
        margin-right: 20px;
        margin-left: 3px;
        &:hover,
        &:focus {
          background-size: 100% auto;
          .transition(all, 0.5s, ease-in);
          opacity: .6;
        }
      }
      a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link) {
        padding: 5px;
        margin-top: 3px;
        &:hover,
        &:focus {
          background-color: transparent;
          color: transparent;
        }
      }
    }
    .col-3-5 .col-1-3:last-child > * {
      float: right;
      margin-top: 15px;
      margin-left: 10px;
    }
    .col-1-3 {
      width: 50%;
    }
    .col-2-5 {
      width: 30%;
    }
    .col-3-5 {
      width: 70%;
      padding-top: 0;
    }
    .content {
      margin-left: 40px;
      a {
        font-family: @font-secondary;
      }
    }
  }
}
.policy {
  width: 100%;
  background: @color-neutral;
  ul {
    width: 970px;
    height: 100%;
    margin: 0 auto;
    li {
      background-color: @color-neutral;
      margin: 0 40px 8px 0;
      padding-top: 8px;
      display: inline-block;
      height: 100%;
      &::before {
        content: "";
      }
      a {
        color: @color-light;
      }
    }
  }
}

@media print {
  .not-for-ansatte #head-wrapper {
    height: 185px;
  }
  .not-for-ansatte #head-wrapper #head #header a {
    color: @color-dark;
  }
  div#content-breadcrumb-container {
    display: none;
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: #7FA8DC;
      #head {
        height: 195px;
        #header {
          width: 100%;
          a#logotext {
            top: 20px;
            left: 0;
            width: 100%;
            display: block;
            .font-size(50);
            text-align: center;
          }
          img#logoimg {
            @media (max-width: 604px) {
              width: 100%;
              max-height: 195px;
              top: unset;
              left: 0;
              bottom: 0;
            }
          }
          span.tagline {
            display: none;
          }
        }
      }
    }

    #menu-wrapper {
      .menu li.active,
      .menu {
        background: @color-contrast;
      }
      .menu li {
        a.toggle-navigation,
        a.toggle-search {
          color: @color-light;
        }
      }
    }
    .menu-search button:after {
      left: -22px;
    }
  }
  div#content-breadcrumb-container {
    width: 100%;
    margin-top: -30px;
    //margin-left: 15px;
    padding-bottom: 15px;
  }
  #vrtx-frontpage div#content-breadcrumb-container {
    margin-left: 15px;
  }
  #main .vrtx-person-listing td {
    border-bottom: none;
  }
  #vrtx-frontpage
    #main
    .grid-container:not(.row-last-colored)
    + .grid-container:not(.row-first-colored) {
    border-top: none;
  }
  #vrtx-frontpage
    #main
    .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red):not(.responsive-hide)
    + .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
    border-top: none;
  }

  .not-for-ansatte #footer-wrapper {
    background: @color-contrast;
    #footers {
      .col-2-5,
      .col-3-5 {
        width: 100%;
      }
      .content {
        margin-left: 10px;
      }
    }
  }
  .policy {
    padding-left: 15px;
  }
}

/*------------------------------------*\
# colour
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #c09782; // sand
@color-primary--light: #c3b0a3; // light sand
@color-primary--thinair: #c3e4ff; //ice blue

@color-contrast--light: #a1796f; //teal light
@color-contrast: #876157; // teal
@color-contrast--dark: #6b5455; //teal dark

@color-link: #485399; //mørk blå som er lysnet

// Neutral colors
@color-neutral--dark: #4c4c4c;
@color-neutral: #6a667a; //mountain blue grey //#929092;
@color-neutral--light: #d2d0d2;
@color-neutral--air: #f4f4f4; //lys gra

// Suplementary colors
@color-light: #ffffff;
@color-dark: #3b3b3b;
@color-black: #000000;
